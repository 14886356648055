<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card grid-list-md>
      <ModelTitle title="Edit Payee" @close="close()" />
    <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              outlined
              :hide-details="nameErrors.length === 0"
            dense
              :error-messages="nameErrors"
              v-model="Payee.name"
              label="Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="Payee.phoneNumber"
              label="Phone Number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="Payee.email"
              label="Email Address"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              outlined
              hide-details
              dense
              v-model="Payee.address"
              label="Address"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-text-field
              outlined
              hide-details
              dense
              v-model="Payee.city"
              label="City"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="Payee.state"
              label="State"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="Payee.zip"
              label="Zip Code"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea
              outlined
              dense
              hide-details
              auto-grow
              v-model="Payee.note"
              :label="$t('labels.description')"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="pink lighten-1" :loading="loading" dark @click="submit()"
          >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import payeeService from "../service.js";

const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  props: {
    editDialog: {
      default: false
    },
    editPayee: {
      type: Object
    }
  },
  name: "update-payee",
  data() {
    return {
      loading: false,
      dialog: false,
      Payee: {
        name: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        note: null
      }
    };
  },
  mixins: [validationMixin],
  validations: {
    Payee: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.Payee.name.$dirty) return errors;
      if (!this.$v.Payee.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    }
  },
  watch: {
    editDialog(val) {
      this.Payee = this.editPayee;
      this.dialog = val;
    }
  },
  methods: {
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.Payee = {
        name: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        note: null
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields")
          });
      } else {
        this.loading = true;
        return payeeService
          .update(this.Payee.id, this.Payee)
          .then(result => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                })
                .fire({
                  icon: "success",
                  title: "Payee is updated"
                });
              this.loading = false;
              this.$emit("submit");
              this.close();
            }
          })
          .catch(err => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000
              })
              .fire({
                icon: "error",
                title: err.data.message
              });
          });
      }
    }
  }
};
</script>

<style scoped></style>
